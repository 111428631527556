import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6fd00856 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _42304ead = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _e6adecec = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _24d3206d = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _6c434452 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _662cdf04 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _694e670d = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _5d245c6c = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _45e03be0 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _d93f42ac = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _66c699f5 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _26c43b32 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _6ea3e7f0 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _15d59494 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _04f022d1 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _379f099b = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _19d8166c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _6fd00856,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _42304ead,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _e6adecec,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _24d3206d,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _6c434452,
    name: "receipt"
  }, {
    path: "/search",
    component: _662cdf04,
    name: "search"
  }, {
    path: "/search-booking",
    component: _694e670d,
    name: "search-booking"
  }, {
    path: "/terms-and-conditions",
    component: _5d245c6c,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _45e03be0,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _d93f42ac,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _66c699f5,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _26c43b32,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _6ea3e7f0,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _15d59494,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/product",
    component: _04f022d1,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _379f099b,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _19d8166c,
    name: "index"
  }, {
    path: "/dedicated",
    component: _19d8166c,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-index"
  }, {
    path: "/dedicated/search",
    component: _662cdf04,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-search"
  }, {
    path: "/dedicated/select-extras/product",
    component: _04f022d1,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-select-extras"
  }, {
    path: "/dedicated/manage-booking/modify",
    component: _26c43b32,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify"
  }, {
    path: "/dedicated/receipt",
    component: _6c434452,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-receipt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
